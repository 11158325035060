.popup-email{
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.484);
}
.popup-email span{
    font-size: 20px;
    letter-spacing: 2px;
    cursor: pointer;
    display: inline-block;
    transition: all 0.5s;
}

.popup-email a{
    color: black !important;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
}
.popup-email span:hover {
    transform: translateY(-5px);
    text-decoration: underline;
}
.popup-email img{
    cursor: default;
    margin-right: 1rem;
}

.content-box{
background-color: white;
transition: all 0.5;
padding: 2em;
border: 10px;
}
.content-box.active{
    overflow: hidden;
        width: 6px;
        height: 6px;
        border-radius: 50% !important;
}
















 