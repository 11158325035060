.footer{
position: fixed;
bottom: 5px;
left: 50%;
transform: translateX(-50%);
font-size: 30px;
font-weight: bold;
color: white;
max-width: 100%;

}
@media (max-width:450px) {
    .footer {
            
            font-size: 20px;
           
    max-width: 100%;
    
        }
}