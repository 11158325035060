.navbar{
    color: white;
    font-size: 20px;
}
.info-email{
    color: white;
    stroke-width: 0.5px;
    cursor: pointer;
}

.sertic_logo{
width: 70px;
height: 70px;
cursor: pointer;
}
.lang.active{
    font-weight:bold;
}
@media (max-width:450px) {
    .sertic_logo {
            width: 50px;
            height: 50px;
          
        }
        .navbar div span{
            font-size: 14px;
        }
}